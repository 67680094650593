/* istanbul ignore file */
import axios from 'axios';
import config from '../config/config';
import {getDefaultDomainDetails, extractClientNameFromURL} from '../util/Extra';

/* const configApi = {
  publicUrl: config.serverURL,
};
 */
extractClientNameFromURL();
const currentClientFromUrl = sessionStorage.getItem('currentClientFromUrl');
const configApi = {
  publicUrl: getDefaultDomainDetails(currentClientFromUrl).server_url,
  apiKey: getDefaultDomainDetails(currentClientFromUrl).api_key,
  institueId: getDefaultDomainDetails(currentClientFromUrl).institute_key,
};

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'api-key': configApi.apiKey,
    'institute-id': configApi.institueId,
  };
};
export default axios.create({
  baseURL: configApi.publicUrl, //YOUR_API_URL HERE
  headers: getHeaders(),
  timeout: 55000, // Wait for 55 seconds
});

/* const mainAxios = axios.create({
  baseURL: configApi.publicUrl, //YOUR_API_URL HERE
  headers: getHeaders(),
  timeout: 55000, // Wait for 55 seconds
});

const scormAxios = axios.create({
  baseURL: 'https://lms-scorm-stage.higherl.com/scorm-logger', //YOUR_API_URL HERE
  headers: getHeaders(),
  timeout: 55000, // Wait for 55 seconds
});

export {mainAxios, scormAxios}; */
